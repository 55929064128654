<template>
  <div class="footer" :class="isBottom">
    <!-- {{$route.about}} -->
    <!-- <span>{{menuData}}</span> -->
    <div class="footer_logo">
      <img src="../assets/logo.png" alt />
    </div>
    <div class="footer_contact">
      <div class="footer_left">
        <span class="span">
            <img style="margin-right: 2px" src="../assets/footer/footer_address_img.png" alt />
          <a
            class="email-text"
            target="_blank"
            href="https://map.baidu.com/search/%E5%8C%97%E4%BA%AC%E5%B8%82%E4%B8%9C%E5%9F%8E%E5%8C%BA%E4%B8%AD%E6%B5%B7%E5%9C%B0%E4%BA%A7%E5%B9%BF%E5%9C%BA%E4%B8%9C%E5%A1%9416%E5%B1%821602/@12957323.455,4820394.36,19z?querytype=s&da_src=shareurl&wd=%E5%8C%97%E4%BA%AC%E5%B8%82%E4%B8%9C%E5%9F%8E%E5%8C%BA%E4%B8%AD%E6%B5%B7%E5%9C%B0%E4%BA%A7%E5%B9%BF%E5%9C%BA%E4%B8%9C%E5%A1%9416%E5%B1%821602&c=131&src=0&pn=0&sug=0&l=17&b=(12956393.398388056,4819974.775262371;12958170.071438534,4820953.333465954)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&seckey=e7ccd76a71cca7384bc9d56993ddbed2e19bbff4744b85e39bb3d65be30e7613e76ae0b8689ae7f5bb14207898aef6950e69432a9314fa542a239fa64bfb5b45a57edbbf8f1bf97ae2b1c6add3772eb73cbb6891014a8e7abd3eb187eb38a6a42f7dc00627d9bbd784f9c1300a9ea46423c36de51b4a0d1495b42264419aa38be105a0a2657382e1d0a85ea4cee365c26a90ee987b700d29be058f42468db3220320b30c52990d911d137c62d47a66ca9ab95984106a87030c6f12502c689bd85e49b14686bae77153e85031fc7c6b6f040bbcbc8c6d8ac06f9c1e407027daa045209879a14b5900bc5542651a1ce95f&device_ratio=2"
          >
           地址：北京市东城区中海地产广场东塔16层1602室
          </a>
        </span>
        <span class="span">
          <img
            style="margin-right: 6px"
            src="../assets/footer/footer_email_img.png"
            alt
          />
          <a href="mailto:Service@jinglaw.cn" class="email-text"
            >邮箱：Service@jinglaw.cn</a
          >
        </span>
        <span class="span">
          <img style="margin-right: 6px" src="../assets/footer/footer_telephon_img.png" alt />
          <a href="tel:010-67131688" class="email-text">电话：010-67131688</a>
        </span>
      </div>
    </div>
    <div class="footer_contact">
      <!-- <div class="footer_left">
        <div>
          <h3>乘车路线</h3>
          <p style="font-weight: 200; font-size: 14px; padding-right: 10px">
            <span style="font-weight: 600; font-size: 14px;margin: auto">线路一：</span
            >北京站坐2号线内环地铁（开往崇文门方向）至崇文门站，站内换乘5号线（开往宋家庄方向）至磁器口站（G东南口）
          </p>
          <p style="font-weight: 200; font-size: 14px; padding-right: 10px">
            <span style="font-weight: 600; font-size: 14px;margin: auto">线路二：</span
            >北京西站乘坐7号线地铁（开往环球度假区方向）至广渠门内站（D西南口）
          </p>
          <p style="font-weight: 200; font-size: 14px; padding-right: 10px;">
            <span style="font-weight: 600; font-size: 14px;margin: auto">线路三：</span
            >北京南站乘坐4号线大兴线地铁（开往安河桥北方向）至菜市口站，站内换乘7号线（开往环球度假区方向）至广渠门内站（D西南口）
          </p>
        </div>
      </div> -->
    </div>
    <div class="tu-div" style="">
      <div class="">
        <img src="../assets/daouyin.jpg" alt="" />
      </div>
      <div>
        <h5>京运抖音：jingyunlawfirm</h5>
        <h5>京运公众号：jingyunlawfirm</h5>
      </div>
    </div>
    <div class="footer_links">
      <span style="margin-left: 10px">友情链接：</span>
      <a href="http://www.mnr.gov.cn/" target="view_window"
        >中华人民共和国自然资源部</a
      >
      <a
        href="http://www.acla.org.cn"
        target="view_window"
        class="footer_links_a"
        >中华全国律师协会</a
      >
      <a
        href="http://sfj.beijing.gov.cn/"
        target="view_window"
        class="footer_links_a"
        >北京市司法局</a
      >
      <a
        href="http://www.bjdclx.org"
        target="view_window"
        class="footer_links_a"
        >北京市东城区律师协会</a
      >
    </div>

    <div class="footer_information">
      <div>
        <span>
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            style="color: #b1afaf; text-decoration: none"
            >ICP备案号：京ICP备2020039628号</a
          > </span
        >&nbsp;&nbsp;
        <span class="span-1">
          <img src="../assets/gongan.png" />
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004393"
            style="color: #b1afaf; text-decoration: none"
          >
            京公网安备：11010102004393
          </a>
        </span>
      </div>
    </div>
    <!-- 弹框 -->
    <div>
      <el-dialog
        title
        :visible.sync="centerDialogVisible"
        margin-top="10vh"
        width="30%"
        center
      >
        <div>
          <img
            v-show="isImg.douyin"
            class="wx douying"
            src="../assets/daouyin.jpg"
            alt
          />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { requestContact } from "@/api";

export default {
  name: "footerModule",

  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
    isBottom: {
      type: String,
      default: () => {},
    },
  },

  data() {
    return {
      isImg: {
        wechat: false,
        douyin: false,
      },
      centerDialogVisible: false,
      about: {
        address: "",
        companyName: "",
        email: "",
        phone: "",
      },
    };
  },
  created() {
    // this.getContact();
  },
  methods: {
    async getContact() {
      let { data } = await requestContact({
        menuId: this.menuData.menuId,
      });
      // debugger;
      this.about.address = data.address;
      this.about.companyName = data.companyName;
      this.about.email = data.email;
      this.about.phone = data.phone;
      this.$store.commit("ABOUT_US", this.about);
    },

    mousenter(name) {
      this.isImg[name] = true;
    },
    mouseleve(name) {
      this.isImg[name] = false;
    },
    douyinDialog() {
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 468px;

  background: #f4f4f4;
  position: relative;
  .footer_logo {
    text-align: center;
    padding-top: 50px;
    > img {
      width: 500px;
      height: 70px;
    }
  }
  .tu-div {
    width: 92%;
    margin: 0 auto;
    padding-top: 10px;
    border-top: 1px solid #cecdd2;
    img {
      width: 100px;
    }
    display: flex;
    justify-content: space-around;
  }
  .footer_contact {
    width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    .footer_left {
      span,
      a {
        margin-right: 20px;
        // text-decoration: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        // color: #b9b9b9;
        line-height: 37px;
      }
    }
    .footer_right {
      width: 120px;
      display: flex;
      justify-content: space-between;
      position: relative;
      img {
        cursor: pointer;
        vertical-align: -4px;
        margin-right: 6px;
      }
      .wx {
        width: 180px;
        position: absolute;
        top: -190px;
        right: -80px;
      }
      .douying {
        width: 180px;
        right: 40px;
      }
    }
  }
  .footer_links {
    margin-top: 40px;
    width: 1200px;
    // height: 60px;
    border-top: 1px solid #777777;
    margin: 0 auto;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #aaaaaa;
    // line-height: 60px;
    margin-top: 20px;
    padding-top: 30px;
    a {
      // color: #b9b9b9;
      text-decoration: none;
    }
    .footer_links_a {
      margin-left: 24px;
    }
  }
  .footer_information {
    width: 100%;
    height: 60px;
    background: #f4f4f4;
    position: absolute;
    bottom: 0;
    > div {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      line-height: 60px;
      > span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #b1afaf;
        line-height: 37px;
        // margin-right: 60px;
      }
      .span-1 {
        margin-left: 20px;
      }
    }
  }
  img {
    vertical-align: -4px;
    // margin-right: 6px;
  }
}
.bottom {
  // position: fixed;
  // bottom: 0;
  // left: 0;
}
@media (max-width: 1000px) {
  .footer {
    width: 100%;
    height: auto;
    background: #f4f4f4;
    position: relative;
    padding-bottom: 80px;
    .footer_logo {
      text-align: center;
      padding-top: 20px;
      > img {
        width: 60%;
        height: auto;
      }
    }
    .footer_contact {
      width: 100%;
      margin: 0 auto;
      margin-top: 0px;
      display: inherit;
      // justify-content: space-between;
      padding-bottom: 20px;
      // flex-flow: row wrap;
      position: relative;
      .footer_left {
        margin-top: 10px;
        margin-left: 20px;
        .span {
          display: block;
          margin-left: 10px;
        }
        span,
        a {
          margin-right: 20px;
          // text-decoration: none;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2c3e50;
          line-height: 20px;
        }
        img {
          vertical-align: -2px;
          width: 14px;
        }
      }
      .footer_right {
        position: absolute;
        margin-left: 10px;
        width: 50px;
        top: 30px;
        right: 70px;
        display: flex;
        float: right;
        justify-content: space-between;
        // position: relative;
        img {
          cursor: pointer;
          vertical-align: -0px;
          margin-right: 6px;
        }
        .wx {
          width: 90px;
          // position: absolute;
          top: -90px;
          right: -60px;
        }
        .douying {
          width: 90px;
          right: 0px;
        }
      }
    }
    .footer_links {
      display: none;
      width: 100%;
      height: 120px;
      border-top: 1px solid #777777;
      margin: 0 auto;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      // color: #aaaaaa;
      // line-height: 60px;
      margin-top: 0px;
      padding-top: 20px;
      a {
        // color: #b9b9b9;
        text-decoration: none;
        display: block;
        margin-left: 10px;
      }
      .footer_links_a {
        margin-left: 10px;
      }
    }
    .footer_information {
      width: 100%;
      height: auto;
      background: #f4f4f4;
      position: relative;
      bottom: 0;
      padding: 20px 0;
      > div {
        width: 100%;
        height: auto;
        margin: 0 auto;
        line-height: 14px;
        text-align: center;
        > span {
          margin-left: 10px;
          margin-top: 10px;
          display: block;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #b1afaf;
          line-height: 14px;
          // margin-right: 60px;
        }
        .span-1 {
          margin-left: 10px;
        }
      }
    }
    img {
      vertical-align: -4px;
      // margin-right: 6px;
    }
  }
  .bottom {
    // position: fixed;
    // bottom: 0;
    // left: 0;
  }
}
.email-text {
  text-decoration: none;
}
</style>
