<template>
  <div class="home" v-loading="loading">
    <navBar :menuList="menuList"></navBar>
    <swiper></swiper>
    <div v-if="menuList.length">
      <div
        :id="'page_' + index"
        :class="'page_' + index + ' page_' + index + '_page'"
        class="page"
        v-for="(item, index) in menuList"
        :key="index"
        v-if="index > 9"
      >
        <div
          :class="'component-title_' + index"
          class="component-title"
          v-if="index > 0 && index !== 4"
        >
          <h1>
            <img class="left" src="../assets/home/left.png" alt="" />
            <span> {{ item.menuName }}</span>

            <img class="right" src="../assets/home/right.png" alt="" />
          </h1>
          <h2 :style="{ backgroundImage: `url(${item.imgPath})` }">
            {{ item.remark }}
          </h2>
        </div>
        <component :is="`page${index}`" :menuData="item"></component>
      </div>
    </div>
    <!-- </full-page> -->

    <footerModule />
    <!-- <contactModule /> -->
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import { requestMenu } from "@/api";
import swiper from "./swiper.vue";
import page10 from "./page1.vue";
import page11 from "./page2.vue";
import page12 from "./page3.vue";
import page13 from "./page4.vue";
import page14 from "./page5.vue";
import page15 from "./page6.vue";
import contactModule from "@/components/contactModule.vue";
import footerModule from "@/components/footerModule.vue";

import { setRem } from "../utils/rem";

export default {
  name: "Home",
  components: {
    navBar,
    swiper,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    // page7,
    // page8,
    contactModule,
    footerModule,
  },
  data() {
    return {
      loading: false,
      menuList: [],
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        onLeave: this.onLeave,
        navigation: true,
        // menu: "#myMenu",
        // navigationPosition: 'right',
        anchors: [
          "page1",
          "page2",
          "page3",
          "page4",
          "page5",
          "page6",
          "page7",
          "page8",
        ],
      },
    };
  },
  created() {
    this.getMenuList();
  },
  methods: {
    async getMenuList() {
      this.loading = true;
      let { data } = await requestMenu();
      this.menuList = data;
      this.loading = false;
    },

    async domHight() {},

    load(dom) {
      var anchor = document.querySelector(dom);
      this.scrollTop(anchor.offsetTop, 500);
    },
  },

  mounted() {
    // this.load("#page3");
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1000px) {
  .component-title {
    text-align: center;
    h1 {
      font-size: 20px;
      margin: 26px 0 0 0;
      color: #222;
      font-weight: 600;
      position: relative;
      .left {
        vertical-align: 3px;
        width: 80px;
        // position: absolute;
        // left: 14%;
        // top: 48%;
        margin-right: 10px;
      }
      .right {
        vertical-align: 3px;
        width: 80px;
        margin-left: 10px;
        // position: absolute;
        // right: 14%;
        // top: 48%;
      }
    }
    h2 {
      font-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0;
      background-size: contain;
      line-height: 20px;
      // padding-top: 10px;

      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #684647;
    }
  }
}
</style>
