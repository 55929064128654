import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/swiper-bundle.css'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/core'

// import 'fullpage.js/vendors/scrolloverflow';
// import VueFullPage from 'vue-fullpage.js';

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import './element-variables.scss'

import globalapproach from './plugin/globalapproach' // 引入


// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(globalapproach) // 添加
// Vue.use(VueFullPage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
