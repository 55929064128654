<template>
    <div id="b1" class="box" @click="handleClick" style="position: relative;left: 0;transition: all .3s" :style="{left: state_1==='menu'?'0':'-300'+'px'}">
        <svg id="i1" class="icon" viewBox="0 0 100 100">
            <path id="top-line-1" d="M30,37 L70,37 Z"></path>
            <path id="middle-line-1" d="M30,50 L70,50 Z"></path>
            <path id="bottom-line-1" d="M30,63 L70,63 Z"></path>
        </svg>
    </div>
</template>

<script>
	import {AJS} from "@/components/svgAni"

	export default {
		name: "menu-icon",
		props: {
			drawer: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				state_1: "menu",
				arrowDisappearComplete_1: false,
				menuAppearComplete_1: false,
				menuDisappearComplete_1: false,
				arrowAppearComplete_1: false,
				segmentDuration_1: 15,
				topLeftX_1: 0,
				topLeftY_1: 0,
				bottomRightX_1: 0,
				bottomRightY_1: 0,
				topLine_1: null,
				bottomLeftX_1: 0,
				bottomLeftY_1: 0,
				topRightX_1: 0,
				topRightY_1: 0,
				bottomLine_1: null,
				currentFrame_1: 1,
				topLineY_1: 0,
				bottomLineY_1: 0,
				arrowAppearDurationInFrames_1: 0,
				menuDisappearDurationInFrames_1: 0,
				arrowDisappearDurationInFrames_1: 0,
				menuAppearDurationInFrames_1: 0,
				middleLine_1: null,

			}
		},
		watch: {
			drawer(newValue) {
				this.state_1 = newValue ? "menu" : "arrow"
				if (this.state_1 === "menu") {
					this.openMenuAnimation_1()
					this.state_1 = "arrow"
					this.arrowDisappearComplete_1 = false
					this.menuAppearComplete_1 = false
				} else if (this.state_1 === "arrow") {
					this.closeMenuAnimation_1()
					this.state_1 = "menu"
					this.menuDisappearComplete_1 = false
					this.arrowAppearComplete_1 = false
				}
			}
		},
		created() {
			//console.log(this.menuDisappearDurationInFrames_1)
			this.$nextTick(() => {
				this.arrowAppearDurationInFrames_1 = this.segmentDuration_1
				this.menuDisappearDurationInFrames_1 = this.segmentDuration_1
				this.arrowDisappearDurationInFrames_1 = this.segmentDuration_1
				this.menuAppearDurationInFrames_1 = this.segmentDuration_1
				this.topLine_1 = document.getElementById("top-line-1")
				this.bottomLine_1 = document.getElementById("bottom-line-1")
				this.middleLine_1 = document.getElementById("middle-line-1")
				// var middleLineY_1

			})
		},
		methods: {
			handleClick() {
				if (this.state_1 === "menu") {
					// this.openMenuAnimation_1()
					// this.state_1 = "arrow"
					// this.arrowDisappearComplete_1 = false
					// this.menuAppearComplete_1 = false
                    this.$emit("click",true)
				} else if (this.state_1 === "arrow") {
					// this.closeMenuAnimation_1()
					// this.state_1 = "menu"
					// this.menuDisappearComplete_1 = false
					// this.arrowAppearComplete_1 = false
					this.$emit("click",false)
				}
			},
			openMenuAnimation_1() {
				if (!this.menuDisappearComplete_1) {
					this.menuDisappearAnimation_1()
				} else if (!this.arrowAppearComplete_1) {
					this.arrowAppearAnimation_1()
				}
			},
			arrowAppearAnimation_1() {
				this.currentFrame_1++
				if (this.currentFrame_1 <= this.arrowAppearDurationInFrames_1) {
					window.requestAnimationFrame(() => {
						//top line
						this.topLeftX_1 = AJS.easeOutBack(30, 35, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.topLeftY_1 = AJS.easeOutBack(50, 35, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.bottomRightX_1 = AJS.easeOutBack(70, 65, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.bottomRightY_1 = AJS.easeOutBack(50, 65, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.topLine_1.setAttribute("d", "M" + this.topLeftX_1 + "," + this.topLeftY_1 + " L" + this.bottomRightX_1 + "," + this.bottomRightY_1)
						//bottom line
						this.bottomLeftX_1 = AJS.easeOutBack(30, 35, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.bottomLeftY_1 = AJS.easeOutBack(50, 65, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.topRightX_1 = AJS.easeOutBack(70, 65, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.topRightY_1 = AJS.easeOutBack(50, 35, this.arrowAppearDurationInFrames_1, this.currentFrame_1)
						this.bottomLine_1.setAttribute("d", "M" + this.bottomLeftX_1 + "," + this.bottomLeftY_1 + " L" + this.topRightX_1 + "," + this.topRightY_1)
						//recursion
						this.arrowAppearAnimation_1()
					})
				} else {
					this.currentFrame_1 = 1
					this.arrowAppearComplete_1 = true
					this.openMenuAnimation_1()
				}
			},
			menuDisappearAnimation_1() {
				this.currentFrame_1++
				if (this.currentFrame_1 <= this.menuDisappearDurationInFrames_1) {
					window.requestAnimationFrame(() => {
						//top line
						this.topLineY_1 = AJS.easeInBack(37, 50, this.menuDisappearDurationInFrames_1, this.currentFrame_1)
						this.topLine_1.setAttribute("d", "M30," + this.topLineY_1 + " L70," + this.topLineY_1)
						//bottom line
						this.bottomLineY_1 = AJS.easeInBack(63, 50, this.menuDisappearDurationInFrames_1, this.currentFrame_1)
						this.bottomLine_1.setAttribute("d", "M30," + this.bottomLineY_1 + " L70," + this.bottomLineY_1)
						//recursion
						this.menuDisappearAnimation_1()
					})
				} else {
					this.middleLine_1.style.opacity = "0"
					this.currentFrame_1 = 1
					this.menuDisappearComplete_1 = true
					this.openMenuAnimation_1()
				}
			},
			closeMenuAnimation_1() {
				if (!this.arrowDisappearComplete_1) {
					this.arrowDisappearAnimation_1()
				} else if (!this.menuAppearComplete_1) {
					this.menuAppearAnimation_1()
				}
			},
			arrowDisappearAnimation_1() {
				this.currentFrame_1++
				if (this.currentFrame_1 <= this.arrowDisappearDurationInFrames_1) {
					window.requestAnimationFrame(() => {
						//top line
						this.topLeftX_1 = AJS.easeInBack(35, 30, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.topLeftY_1 = AJS.easeInBack(35, 50, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.bottomRightX_1 = AJS.easeInBack(65, 70, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.bottomRightY_1 = AJS.easeInBack(65, 50, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.topLine_1.setAttribute("d", "M" + this.topLeftX_1 + "," + this.topLeftY_1 + " L" + this.bottomRightX_1 + "," + this.bottomRightY_1)
						//bottom line
						this.bottomLeftX_1 = AJS.easeInBack(35, 30, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.bottomLeftY_1 = AJS.easeInBack(65, 50, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.topRightX_1 = AJS.easeInBack(65, 70, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.topRightY_1 = AJS.easeInBack(35, 50, this.arrowDisappearDurationInFrames_1, this.currentFrame_1)
						this.bottomLine_1.setAttribute("d", "M" + this.bottomLeftX_1 + "," + this.bottomLeftY_1 + " L" + this.topRightX_1 + "," + this.topRightY_1)
						//recursion
						this.arrowDisappearAnimation_1()
					})
				} else {
					this.middleLine_1.style.opacity = "1"
					this.currentFrame_1 = 1
					this.arrowDisappearComplete_1 = true
					this.closeMenuAnimation_1()
				}
			},
			menuAppearAnimation_1() {
				this.currentFrame_1++
				if (this.currentFrame_1 <= this.menuAppearDurationInFrames_1) {
					window.requestAnimationFrame(() => {
						//top line
						this.topLineY_1 = AJS.easeOutBack(50, 37, this.menuDisappearDurationInFrames_1, this.currentFrame_1)
						this.topLine_1.setAttribute("d", "M30," + this.topLineY_1 + " L70," + this.topLineY_1)
						//bottom line
						this.bottomLineY_1 = AJS.easeOutBack(50, 63, this.menuDisappearDurationInFrames_1, this.currentFrame_1)
						this.bottomLine_1.setAttribute("d", "M30," + this.bottomLineY_1 + " L70," + this.bottomLineY_1)
						//recursion
						this.menuAppearAnimation_1()
					})
				} else {
					this.currentFrame_1 = 1
					this.menuAppearComplete_1 = true
					this.closeMenuAnimation_1()
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
    .icon {
        position: relative;
        top: 30%;
        left: 90%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        fill: none;
        stroke-width: 6;
        stroke-linecap: round;
        stroke-linejoin: round;
        cursor: pointer;
    }

    .box {
        width: 40px;
        height: 40px;
        float: left;
    }

    .icon {
        stroke: #b71c21;
        opacity: .95;
    }

    /*#b1 { background: #fc5c65; }*/
</style>
