<template>
  <div>
    <ul>
      <li v-for="(item, index) in list" :key="index" @click="active(index)">
        <!-- activeIndex == index ? `url(${item.img})` : `url(${item.imgbj})`, -->
        <div :style="{}">
          <img
            :src="activeIndex == 1000 ? `${item.img}` : `${item.imgbj}`"
            alt=""
          />
        </div>
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import { requestBanner } from "@/api";
import btn from "@/components/btn";

import img1 from "../assets/home/page6/1/page1.png";
import imgbj1 from "../assets/home/page6/2/page1.png";
import img2 from "../assets/home/page6/1/page2.png";
import imgbj2 from "../assets/home/page6/2/page2.png";
import img3 from "../assets/home/page6/1/page3.png";
import imgbj3 from "../assets/home/page6/2/page3.png";
import img4 from "../assets/home/page6/1/page4.png";
import imgbj4 from "../assets/home/page6/2/page4.png";
import img5 from "../assets/home/page6/1/page5.png";
import imgbj5 from "../assets/home/page6/2/page5.png";
import img6 from "../assets/home/page6/1/page6.png";
import imgbj6 from "../assets/home/page6/2/page6.png";
import img7 from "../assets/home/page6/1/page7.png";
import imgbj7 from "../assets/home/page6/2/page7.png";
import img8 from "../assets/home/page6/1/page8.png";
import imgbj8 from "../assets/home/page6/2/page8.png";

export default {
  name: "page6",
  components: {
    Swiper,
    SwiperSlide,
    btn,
  },
  data() {
    return {
      activeIndex: "",
      list: [
        {
          name: "量身定制",
          img: img1,
          imgbj: imgbj1,
        },
        {
          name: "经验丰富",
          img: img2,
          imgbj: imgbj2,
        },
        {
          name: "精英律师",
          img: img3,
          imgbj: imgbj3,
        },
        {
          name: "专业高效",
          img: img4,
          imgbj: imgbj4,
        },
        {
          name: "一站服务",
          img: img5,
          imgbj: imgbj5,
        },
        {
          name: "价格透明",
          img: img6,
          imgbj: imgbj6,
        },
        {
          name: "策略精准",
          img: img7,
          imgbj: imgbj7,
        },
        {
          name: "坚守正义",
          img: img8,
          imgbj: imgbj8,
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {},

  methods: {
    active(index) {
      this.activeIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .activeDiv {
    background: #fff;
    border: 1px solid #b71c21;
    box-sizing: border-box;
    span {
      color: #b71c21;
    }
  }
  ul {
    padding-bottom: 28px;
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    li {
      // background: gray;
      width: 22%;
      margin-top: 10px;
      position: relative;
      text-align: center;
      div {
        border-radius: 50%;
        background: #b71c21;
        text-align: center;
        height: 60px;
        width: 60px;
        // line-height: 60px;
        // background: #b71c21;
        margin-left: 4px;
        // border: 1px solid gray;
        img {
          height: 30px;
          margin-top: 13px;
          vertical-align: 10px;
        }
      }
      span {
        margin-top: 8px;
        display: block;
        // margin-top: 66px;
        // color: #fff;
        font-size: 16px;
      }
    }
    li::before {
    }
  }
}
</style>



