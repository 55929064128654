<template>
  <div>
    <ul>
      <li v-for="(item, index) in list" :key="index" @click="active(index)">
        <!-- activeIndex == index ? `url(${item.img})` : `url(${item.imgbj})`, -->
        <div
          :style="{
            backgroundImage: `url(${item.img})`,
          }"
          
        >
          <span>{{ item.name }}</span>
        </div>
      </li>
    </ul>
     <img
        style="width: 100%; margin: 10px 0"
        src="../assets/home/page2/bj2.jpg"
        alt=""
      />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import { requestBanner } from "@/api";
import btn from "@/components/btn";

import img1 from "../assets/home/wq/fw.png";
import imgbj1 from "../assets/home/wq/fw_bj.png";
import img2 from "../assets/home/wq/td.png";
import imgbj2 from "../assets/home/wq/td_bj.png";
import img3 from "../assets/home/wq/jf.png";
import imgbj3 from "../assets/home/wq/jf_bj.png";
import img4 from "../assets/home/wq/nc.png";
import imgbj4 from "../assets/home/wq/nc_bj.png";
import img5 from "../assets/home/wq/cf.png";
import imgbj5 from "../assets/home/wq/cf_bj.png";
import img6 from "../assets/home/wq/sp.png";
import imgbj6 from "../assets/home/wq/sp_bj.png";
import img7 from "../assets/home/wq/wz.png";
import imgbj7 from "../assets/home/wq/wz_bj.png";
import img8 from "../assets/home/wq/hb.png";
import imgbj8 from "../assets/home/wq/hb_bj.png";

export default {
  name: "page1",
  components: {
    Swiper,
    SwiperSlide,
    btn,
  },
  data() {
    return {
      activeIndex: "",
      list: [
        {
          name: "房屋拆迁",
          img: img1,
          imgbj: imgbj1,
        },
        {
          name: "土地征收",
          img: img2,
          imgbj: imgbj2,
        },
        {
          name: "旧房改造",
          img: img3,
          imgbj: imgbj3,
        },
        {
          name: "农村拆迁",
          img: img4,
          imgbj: imgbj4,
        },
        {
          name: "厂房拆迁",
          img: img5,
          imgbj: imgbj5,
        },
        {
          name: "商铺拆迁",
          img: img6,
          imgbj: imgbj6,
        },
        {
          name: "违章拆迁",
          img: img7,
          imgbj: imgbj7,
        },
        {
          name: "环保关停",
          img: img8,
          imgbj: imgbj8,
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {},

  methods: {
    active(index) {
      this.activeIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .activeDiv {
    background: #fff;

    border: 1px solid #b71c21;
    background-size: 46% 46%;
    background-origin: none;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 26%;
    span {
      color: #b71c21;
    }
  }
  ul {
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    li {
      // background: gray;
      width: 22%;
      margin-top: 10px;
      position: relative;
      border-radius: 12px;

      background: #b71c21;
      div {
        top: 0;
        left: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        // line-height: 60px;
        position: absolute;
        // background: #b71c21;
        // border: 1px solid gray;
        border-radius: 12px;
        background-size: 46% 46%;
        background-origin: none;
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-position-y: 26%;

        span {
          display: inline-block;
          margin-top: 46px;
          color: #fff;
          font-size: 11px;
        }
      }
    }
    li::before {
      content: "";
      padding-top: 100%;
      display: block;
    }
  }
}
</style>



