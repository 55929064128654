import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: '3213',
    menuData: [],
    domHight: [],
    about: {
      address: '',
      companyName: '',
      email: '',
      phone: '',
    }
  },
  mutations: {
    'SET_MENU': (state, val) => {
      state.menuData = val
    },
    'DOME_HIGHT': (state, val) => {
      state.domHight = val
    },
    'ABOUT_US': (state, val) => {
      state.about = val
    }
  },
  actions: {

  },
  getters: {
    menuData: (state) => state.menuData,
    domHight: (state) => state.domHight,
    about: (state) => state.about,
  }
})
