export default {
    install(Vue, options) {
        Vue.prototype.$toTop = function () {
            // 储存模块坐标
            let domHightArr = [];
            let pageArr = document.querySelectorAll(".page");
            pageArr.forEach((val, index, arr) => {
                let obj = {};
                // let dom = document.querySelector(".page_" + i);
                obj.height = val.offsetTop;
                if (index === 0) {
                    obj.height = 0;
                }
                domHightArr.push(obj);
            });
            domHightArr.push({ height: document.body.scrollHeight });
            this.$store.commit("DOME_HIGHT", domHightArr);
        }
    }
}