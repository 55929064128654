<template>
  <div class="page3" style="margin-top: 20px">
    <img style="width: 100%" src="../assets/home/page3/bj.png" alt="" />

    <div class="content">
      <div style="width: 92%">
        <img
          @click="clicImg(0)"
          style="width: 100%; border-radius: 12px"
          src="../assets/home/page5/tu1.jpg"
          alt=""
        />
        <div style="display: flex; justify-content: space-between">
          <img
            @click="clicImg(1)"
            style="width: 48%; border-radius: 12px; height: 260px"
            src="../assets/home/page5/tu2.png"
            alt=""
          />
          <img
            @click="clicImg(2)"
            style="width: 48%; border-radius: 12px; height: 260px"
            src="../assets/home/page5/tu3.png"
            alt=""
          />
        </div>
      </div>
      <!-- 弹框 -->
      <div>
        <el-dialog
          class="div-dialog"
          title
          :visible.sync="centerDialogVisible"
          margin-top="10vh"
          width="100%"
          center
        >
          <div>
            <!-- <img
              style="width: 100%"
              src="../assets/home/page5/tu1.jpg"
              alt=""
            /> -->
            <img style="width: 100%" :src="imgurl" alt="" />
          </div>
        </el-dialog>
      </div>
      <div @click="link()" class="btn">
        <btn btnTitle="查看更多"></btn>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { requestMenu, requestArticleDetail, requestArticleList } from "@/api";
import btn from "@/components/btn";

import img1 from "../assets/home/page5/tu1.jpg";
import img2 from "../assets/home/page5/tu2.png";
import img3 from "../assets/home/page5/tu3.png";

export default {
  name: "pag5",
  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    btn,
  },
  data() {
    return {
      imgurl: "",
      imgList: [img1, img2, img3],
      centerDialogVisible: false,
      menuList: [],
      articleList: [],
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
    };
  },
  computed: {},
  created() {
    this.getMenu();
  },

  methods: {
    async getMenu() {
      let { data } = await requestMenu({ menuId: this.menuData.menuId });

      console.log(data);
      this.menuList = data;
      if (this.menuList.length > 0) {
        this.requestArticleList(this.menuList[0].menuId);
        // this.activeIndex = this.menuList[0].menuId;
      }
      // this.intnData();
    },

    clicImg(index) {
      this.centerDialogVisible = true;

      this.imgurl = this.imgList[index];
    },

    link() {
      // let query = {
      //   menuId: this.menuData.menuId,
      //   show: 0,
      // };
      // this.$toTop();
      // // 详情页面
      // this.$router.push({ path: "/listModule5", query });
    },

    // 文章
    requestArticleList(id, index) {
      let that = this;
      requestArticleList({
        menuId: id,
        ...this.page,
      }).then((res) => {
        that.articleList = res.data;
        that.page = res.page;
      });

      //   console.log(data);
    },

    //文章详情
    particulars(id) {
      this.$router.push({
        path: "/pageParticulars",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .page3 {
    width: 100%;
    margin: 0 auto;
    position: relative;
    .content {
      width: 100%;

      position: absolute;
      top: 2%;
      // left: 4%;
      width: 100%;
      height: 100%;
      div {
        margin: 0 auto;
      }
      ul {
        padding: 20px;
      }
    }
  }
  .content-ul {
    // padding-bottom: 10px;
    li {
      background: #fff;
      padding: 20px 10px;
      div {
        // color: #fff;
        position: relative;
        video {
          width: 100%;
          height: 100%;
          // border: 1px solid gray;
          background: red;
          padding-top: 10px;
        }
        img {
          position: absolute;
          top: -5px;
          right: 20px;
          width: 24px;
        }
      }
    }
  }
  .btn {
    width: 60%;
    height: 40px;
    line-height: 38px;
    margin: 0 auto;
    margin-top: 38px !important;
  }
}
</style>



