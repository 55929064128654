<template>
  <div class="page4">
    <div class="header_img">
      <img
        style="width: 100%; margin: 10px 0"
        src="../assets/home/page2/bj1.jpg"
        alt=""
      />
    </div>
    <div>
      <ul class="menuName-ul">
        <li v-for="(item, index) in menuList" :key="index">
          <div
            :class="num == index ? 'activeDiv' : ''"
            @click="getNum(index, item.menuId)"
          >
            {{ item.menuName }}
          </div>
        </li>
      </ul>
    </div>
    <!--  -->
    <div>
      <ul class="content-ul">
        <li v-for="(item, index) in articleList" :key="index" v-if="index < 1">
          <div
            @click="particulars(item.id)"
            style="
              display: flex;
              justify-content: space-between;
              height: 80px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 15px;
            "
          >
            <div style="width: 40%">
              <img
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
                src="../assets/home/page2/bj1.jpg"
                alt=""
              />
            </div>
            <div style="width: 58%">
              <div
                style="
                  margin-top: 4px;
                  font-size: 18px;
                  letter-spacing: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.title }}
              </div>
              <div
                style="
                  margin-top: 5px;
                  font-size: 14px;
                  letter-spacing: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.summary }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--  -->
    <div class="div">
      <ul>
        <li v-for="(item, index) in articleList" :key="index" v-if="index < 4">
          <div
            @click="particulars(item.id)"
            style="display: flex; justify-content: space-between"
          >
            <span
              ><span
                style="
                  width: 4px;
                  height: 4px;
                  background: #b71c21;
                  margin-right: 10px;
                  display: inline-block;
                  vertical-align: 3px;
                "
              ></span
              >{{ item.title.slice(0, 13)
              }}{{ item.title.length > 13 ? "..." : "" }}</span
            >
            <span style="color: #b71c21">了解详情></span>
          </div>
        </li>
      </ul>
    </div>
    <div @click="link()" class="btn">
      <btn btnTitle="了解更多"></btn>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import btn from "@/components/btn";
import { requestMenu, requestArticleDetail, requestArticleList } from "@/api";

export default {
  name: "pag4",
  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    btn,
  },
  data() {
    return {
      num: 0,
      menuList: [],
      articleList: [],
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
    };
  },
  computed: {},
  created() {
    this.getMenu();
    // console.log(this.$store.state.menuData[8].menuId);
  },

  methods: {
    async getMenu() {
      let { data } = await requestMenu({
        menuId: this.$store.state.menuData[7].menuId,
      });

      console.log(data);
      console.log(444);
      this.menuList = data;
      if (this.menuList.length > 0) {
        this.requestArticleList(this.menuList[0].menuId);
        // this.activeIndex = this.menuList[0].menuId;
      }
      // this.intnData();
    },

    async getNum(index, id) {
      this.num = index;
      this.requestArticleList(id);
    },

    link() {
      let query = {
        menuId: this.$store.state.menuData[7].menuId,
        show: 0,
      };

      this.$toTop();

      // 详情页面
      this.$router.push({ path: "/listModule", query });
    },

    // 文章
    requestArticleList(id, index) {
      let that = this;
      requestArticleList({
        menuId: id,
        ...this.page,
      }).then((res) => {
        that.articleList = res.data;
        that.page = res.page;
      });

      //   console.log(data);
    },

    //文章详情
    particulars(id) {
      this.$router.push({
        path: "/pageParticulars",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .page2 {
    width: 92%;
    margin: 0 auto;
  }
  .menuName-ul {
    width: 97%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    flex-flow: row wrap;
    li {
      width: 33%;
      text-align: center;
      margin-top: 8px;
      div {
        margin-left: 8px;
        width: 70%;
        padding: 10px 10px;
        font-size: 14px;
        border: 1px solid #d1d1d1;
        border-radius: 12px;
        text-align: center;
      }
    }
  }
  .activeDiv {
    background: #b71c21;
    color: #fff;
    border: none;
  }
  .content-ul {
    width: 92%;
    margin: 0 auto;
    // padding-bottom: 10px;
    li {
      background: #fff;
      padding: 20px 10px;
      div {
        // color: #fff;
        position: relative;
        video {
          width: 100%;
          height: 100%;
          // border: 1px solid gray;
          background: red;
          padding-top: 10px;
        }
        img {
          position: absolute;
          top: -5px;
          right: 20px;
          width: 24px;
        }
      }
    }
  }
  .div {
    width: 92%;
    margin: 0 auto;
    ul {
      li {
        margin: 14px 0;
        div {
          span {
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
  }
  .btn {
    // background: #b71c21;
    width: 60%;
    height: 40px;
    line-height: 38px;
    margin: 0 auto;
  }
}
</style>



