<template>
  <div class="page3">
    <img style="width: 100%" src="../assets/home/page3/bj.png" alt="" />

    <div class="content">
      <ul style="display: flex; justify-content: space-around">
        <li v-for="(item,index) in titleList" :key="item.id">
          <span
            style="
              font-size: 22px;
              color: #fff;
              display: block;
              text-align: center;
            "
            :class="index==2?'fontSize':''"
            >{{ item.name1 }}</span
          >
          <span
            style="
              font-size: 15px;
              color: #fff;
              display: block;
              text-align: center;
            "
            >{{ item.name2 }}</span
          >
        </li>
      </ul>
      <ul class="content-ul">
        <li v-for="(item, index) in articleList" :key="index" v-if="index < 3">
          <div
            style="display: flex; justify-content: space-between"
            @click="details(item.id)"
          >
            <div style="width: 52%">
              <img class="img1" src="../assets/home/page3/icon.png" alt="" />
              <img class="img2" :src="item.imgPath" alt="" />
            </div>
            <div style="width: 40%">
              <div
                style="
                  font-size: 18px;
                  margin-top: 5px;
                  letter-spacing: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.title }}
              </div>
              <div
                style="
                  font-size: 14px;
                  margin-top: 5px;
                  letter-spacing: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.summary }}
              </div>
            </div>
          </div>
        </li>
        
      </ul>
      <div class="btn" @click="particulars()">
        <!-- <div class="btn"> -->
        <btn btnTitle="查看更多"></btn>
      </div>
    </div>
    <!-- 弹框 -->
    <div>
      <el-dialog
        class="div-dialog"
        title
        :visible.sync="centerDialogVisible"
        margin-top="10vh"
        width="60%"
        center
      >
        <div>
          <div class="article-title">{{ menuDatas.title }}</div>
          <div v-if="!menuDatas.content">没有内容！</div>
          <p
            v-html="menuDatas.content"
            v-else
            class="content-wrap content-text;width:100%"
          ></p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { requestMenu, requestArticleDetail, requestArticleList } from "@/api";
import btn from "@/components/btn";

export default {
  name: "page3",
  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    btn,
  },
  data() {
    return {
      menuDatas: {
        dialogLoading: false,
        content: "",
        title: "",
        imgPath: "",
      },
      centerDialogVisible: false,
      titleList: [
        { name1: "32项", name2: "专业维权步骤" },
        { name1: "10余年", name2: "拆迁经验" },
        { name1: "1000+", name2: "团队成功案件" },
      ],
      menuList: [],
      articleList: [],
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
    };
  },
  computed: {},
  created() {
    this.getMenu();
  },

  methods: {
    async getMenu() {
      let { data } = await requestMenu({ menuId: this.menuData.menuId });

      console.log(data);
      this.menuList = data;
      if (this.menuList.length > 0) {
        this.requestArticleList(this.menuList[0].menuId);
        // this.activeIndex = this.menuList[0].menuId;
      }
      // this.intnData();
    },

    // 文章
    requestArticleList(id, index) {
      let that = this;
      requestArticleList({
        menuId: id,
        ...this.page,
      }).then((res) => {
        that.articleList = res.data;
        console.log(that.articleList);
        that.page = res.page;
      });

      //   console.log(data);
    },

    //文章详情
    // 弹框
    // details(item) {
    //   let that = this;
    //   requestArticleDetail({ id: item.id }).then((res) => {
    //     that.menuDatas = res.data;
    //     that.menuDatas.dialogLoading = false;
    //     that.menuDatas.content = res.data.content;
    //     that.menuDatas.title = res.data.title;
    //     that.menuDatas.imgPath = res.data.imgPath;
    //     that.centerDialogVisible = true;
    //   });
    // },

    async getMenuList() {
      this.loading = true;
      let { data } = await requestMenu();
      this.menuList = data;
      this.loading = false;
    },

    //文章详情
    particulars() {
      debugger;
      this.$router.push({
        path: "/page3Particulars",
        query: { menuId: this.$store.state.menuData[12].menuId },
      });
    },

    //文章详情
    details(id) {
      this.$router.push({
        path: "/pageParticulars",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .fontSize{
   margin-top: 3px;
  }
  .page3 {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 10px;
    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ul {
        padding: 20px;
      }
    }
  }
  .content-ul {
    // padding-bottom: 10px;
    li {
      background: #fff;
      padding: 10px 10px;
      margin-top: 10px;
      div {
        // color: #fff;
        position: relative;
        video {
          width: 100%;
          height: 100%;
          // border: 1px solid gray;
          // background: red;
          padding-top: 10px;
        }
        .img1 {
          position: absolute;
          top: -22px;
          right: 20px;
          width: 24px;
        }
        .img2 {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .btn {
    width: 60%;
    height: 40px;
    line-height: 38px;
    margin: 0 auto;
    margin-top: 24px;
  }
}
</style>



