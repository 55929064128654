<template>
  <div class="contact" :class="menuData ? 'contact-bj' : ''">
    <div class="contact_concent">
      <div class="contact_left">
        <div class="div_title"></div>
        <div>
          <div class="btn">
            <btn btnTitle="点击在线咨询京运律师" onclick="window.open('http://p.qiao.baidu.com/cps/chat?siteId=15869372&userId=31350453&siteToken=730f6e8d0942a32570f76346adb1f82b&cp=&cr=www.jinglaw.cn&cw=')"></btn>
          </div>
          <div class="header-div">
            <span>致力于为您提供 最有温度的</span>
            <span>拆迁维权服务</span>
          </div>
          <span class="span_email">
            <img src="../assets/mailbox_img.png" alt />
            <a
              :href="'mailto:' + $store.state.about.email"
              class="email-text"
              >{{ $store.state.about.email }}</a
            >
          </span>
          <span class="span_phone">
            <img src="../assets/telephone_img.png" alt />

            <a :href="'tel:' + $store.state.about.phone" class="email-text">
              {{ $store.state.about.phone }}</a
            >
          </span>
        </div>
      </div>
      <div class="contact_right">
        <img src="../assets/wx.jpg" alt />
      </div>
    </div>
  </div>
</template>

<script>
import { requestContact } from "@/api";
import btn from "@/components/btn";
export default {
  components: {
    btn,
  },
  name: "contactModule",
  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
    a: {
      type: String,
    },
  },
  data() {
    return {
      about: {
        address: "",
        companyName: "",
        email: "",
        phone: "",
      },
    };
  },
  created() {
    this.getContact();
  },
  methods: {
    async getContact() {
      let that = this;
      // let { data } = await requestContact({
      if (this.menuData != null) {
        requestContact({
          menuId: that.menuData.menuId,
        }).then((res) => {
          that.about.address = res.data.address;
          that.about.companyName = res.data.companyName;
          that.about.email = res.data.email;
          that.about.phone = res.data.phone;

          that.$store.commit("ABOUT_US", this.about);
          // console.log(that.about);
        });
      }
    },

    mousenter() {
      this.wx = true;
    },
    mouseleve() {
      this.wx = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  width: 100%;
  height: 220px;
  margin-top: 100px;
  background: #fff;
  background: url(../assets/background_img.jpg) no-repeat;
  background-size: 100% 101%;
  .contact_concent {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .contact_left {
      // float: left;
      width: 80%;
      margin: 0;
      .div_title {
        width: 800px;
        height: 121px;
        font-size: 45px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ac1c21;
        line-height: 121px;
      }
      .btn {
        width: 240px;
        height: 60px;
        display: inline-block;
      }
      .span_email {
        cursor: pointer;
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-left: 45px;
        img {
          vertical-align: middle;
          margin-right: 12px;
          width: 28px;
        }
        a {
          font-size: 19px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
        }
      }
      .header-div {
        display: none;
      }
      .span_phone {
        cursor: pointer;
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-left: 45px;
        img {
          width: 24px;
          vertical-align: -4px;
          // margin-right: 12px;
        }
      }
    }
    .contact_right {
      // float: right;
      margin-right: 15px;
      width: 140px;
      // border: 1px solid red;
      background: #fff;
      height: 140px;
      margin-top: 38px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.contact-bj {
  background: url(../assets/contact-bj.jpg) no-repeat;
  background-size: 100% 101%;
}
.email-text {
  color: #000;
  text-decoration: none;
}
@media (max-width: 1000px) {
  .contact {
    width: 100%;
    height: 110px;
    margin-top: 20px;
    background: #fff;
    background: url(../assets/web/bj1.png) no-repeat;
    // background: none;
    background-size: 100% 101%;
    .contact_concent {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .contact_left {
        // float: left;
        width: 80%;
        margin: 0;
        .div_title {
          width: 70%;
          height: 20px;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ac1c21;
          line-height: 30px;
        }
        .btn {
          width: 120px;
          height: 30px;
          display: inline-block;
          display: none;
        }
        .span_email {
          cursor: pointer;
          font-size: 19px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          margin-left: 45px;
          img {
            vertical-align: -2px;
            margin-right: 4px;
            width: 14px;
          }
          a {
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
          }
        }
        .header-div {
          display: block;
          span {
            display: block;
            font-size: 12px;
            margin-left: 46px;
            color: #b71b21;
          }
        }
        .span_phone {
          cursor: pointer;
          display: inline-block;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          margin-left: 45px;
          img {
            width: 14px;
            vertical-align: -2px;
            // margin-right: 12px;
          }
        }
      }
      .contact_right {
        // float: right;
        margin-right: 30px;
        width: 60px;
        // border: 1px solid red;
        background: #fff;
        height: 60px;
        margin-top: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .contact-bj {
    background: url(../assets/web/bj3.png) no-repeat;
    background-size: 100% 101%;
  }
}
</style>
