import http from './http'
import store from '../store'

// 菜单栏 menu
export const requestMenu = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/menu/treelist',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            if (!params.menuId) {
                store.commit('SET_MENU', res.data)
            }
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 单页 single
const requestSingle = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/single/info',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 成员 member
const requestMemberList = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/member/list',
        params: params
    };
    config.params.status = 0
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
const requestMemberDetail = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/member/info',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 文章 article
const requestArticleList = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/article/list',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
const requestArticleDetail = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/article/info',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 网站配置 Setting
const requestSettingList = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/setting/list',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 网站配置 Setting
const requestSettingInfo = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/setting/info',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 轮播图 banner
const requestBanner = (params={}) => {
    let config = {
        method: 'get',
        url: '/website/banner/list',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
// 联系我们 contact
const requestContact = (params={}) => {

    let config = {
        method: 'get',
        url: '/website/contactus/info',
        params: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            // debugger
            resolve(res)

        }).catch(err => {
            reject(err)
        })
    })
}
// 留言管理
const postMessage = (params={}) => {
    let config = {
        method: 'post',
        url: '/website/message/save',
        data: params
    };
    return new Promise(function (resolve, reject) {
        http.request(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export {
    requestSingle,
    requestMemberList,
    requestMemberDetail,
    requestArticleList,
    requestArticleDetail,
    requestSettingList,
    requestSettingInfo,
    requestBanner,
    requestContact,
    postMessage
}
