
import axios from 'axios'
const WEB_CODE ='gh_fa5bdd6b839b'

const http = axios.create({
    baseURL: 'https://cms.pinlv.cn',
    // baseURL: 'https://cms.ciceroai.com',
    timeout: 60000
});

axios.defaults.headers.get['Cache-Control'] = 'no-cache';

http.interceptors.request.use(config => {
    if (config.method==='get') {
        if (config.params) {
            if (!config.params.account) {
                config.params.account = WEB_CODE
            }
        } else {
            config.params = { account: WEB_CODE }
        }
        // config.params.lang = store.state.app.lang
    }
    if (config.method==='post') {
        if (config.data) {
            if (!config.data.account) {
                config.data.account = WEB_CODE
            }
        } else {
            config.data = { account: WEB_CODE }
        }
    }
    return config
});

http.interceptors.response.use(response => {
        return new Promise(function (resolve, reject) {
            if (response.status === 200 && response.data.success === true) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
    }, error => {
        if (!error.response) {
            error.response = {}
        }
        if (!error.response.data) {
            error.response.data = {}
        }
        if (error.response.status === 403) {
            error.response.data.tryRefresh = true
        }
        return Promise.reject(error.response.data)
    }
);

export default http
