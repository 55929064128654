import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: false //需要被缓存的组件
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      keepAlive: true //需要被缓存的组件
    },
  },
  {
    path: '/pageParticulars',
    name: 'pageParticulars',
    component: () => import(/* webpackChunkName: "about" */ '../views/pageParticulars.vue'),
    meta: {
      // keepAlive:true //需要被缓存的组件
    },
  },
  {
    path: '/listModule',
    name: 'ListModule',
    component: () => import(/* webpackChunkName: "about" */ '@/views/page4_article.vue'),
    meta:{
      keepAlive:false //需要被缓存的组件
   },
  },
  {
    path: '/listModule5',
    name: 'ListModule5',
    component: () => import(/* webpackChunkName: "about" */ '@/views/page5_aritice.vue'),
    meta:{
      keepAlive:false //需要被缓存的组件
   },
  },
  {
    path: '/page3Particulars',
    name: 'page3Particulars',
    component: () => import(/* webpackChunkName: "about" */ '@/views/page3Particulars.vue'),
    meta:{
      keepAlive:false //需要被缓存的组件
   },
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, form, savedPosition) {
    //scrollBehavior方法接收to，form路由对象
    //第三个参数savedPosition当且仅当在浏览器前进后退按钮触发时才可用
    //该方法会返回滚动位置的对象信息，如果返回false，或者是一个空的对象，那么不会发生滚动
    //我们可以在该方法中设置返回值来指定页面的滚动位置，例如：
    //  return {x:0,y:0}
    //表示在用户切换路由时让是所有页面都返回到顶部位置
    // 扩展 
    if (sessionStorage.getItem('scrollpage')) {
      let domHeight = sessionStorage.getItem('scrollpage');
      return { x: 0, y: domHeight - 0 }
    }

    //如果返回savedPosition,那么在点击后退按钮时就会表现的像原生浏览器一样，返回的页面会滚动过到之前按钮点击跳转的位置，大概写法如下：
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
    //如果想要模拟滚动到锚点的行为:
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

export default router
