<template>
  <div>
    <!-- 轮播图 -->
    <div ref="mySwiper" class="page-wrap display-web">
      <swiper :options="swiperOptions" v-if="listWeb.length">
        <swiper-slide v-for="(item, index) in listWeb" :key="index">
          <img :src="item.imgPath" alt />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- <div class="btn">
        <btn btnTitle="点击在线咨询京运律师" onclick="window.open('http://p.qiao.baidu.com/cps/chat?siteId=15869372&userId=31350453&siteToken=730f6e8d0942a32570f76346adb1f82b&cp=&cr=www.jinglaw.cn&cw=')"></btn>
      </div> -->
    </div>
    <!-- 地图 -->
    <img
      v-if="!isHide"
      style="width: 100%; margin-top: -8px"
      src="../assets/swiper/banner.jpg"
      alt=""
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { requestBanner } from "@/api";
import btn from "@/components/btn";

// web 轮播图
import swiper1 from "../assets/swiper/1.jpg";
import swiper2 from "../assets/swiper/2.jpg";
import swiper3 from "../assets/swiper/3.jpg";

export default {
  name: "page1",
  props: {
    isHide: {
      type: Boolean,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    btn,
  },
  data() {
    return {
      list: [],
      listWeb: [
        { imgPath: swiper1 },
        { imgPath: swiper2 },
        { imgPath: swiper3 },
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 2000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: true,
        },
        //开启循环模式
        loop: true,
        spaceBetween: 0,
        onInit: function (swiper) {
          //回调函数，初始化后执行。
          swiper.slides[0].className = "swiper-slide swiper-slide-active"; //第一次打开不要动画
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    this.getImgList();
  },

  methods: {
    async getImgList() {
      let { data } = await requestBanner();
      this.list = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.display-pc {
  display: block;
}
.display-web {
  display: none;
}
.page-wrap {
  width: 100%;
  position: relative;
  .div-btn {
    position: absolute;
    top: 70%;
    left: 23%;
  }
  img {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .display-pc {
    display: none;
  }
  .display-web {
    display: block;
    .btn {
      margin: 0 auto;
      // height: 30px;
      margin-top: 20px;
      padding: 12px 0;
      width: 180px;
      text-align: center;
      background: #b81c22;
      border-radius: 5px;
    }
  }
  .page-wrap {
    width: 100%;
    position: relative;
    .div-btn {
      position: absolute;
      top: 66%;
      left: 23%;
    }
    img {
      width: 100%;
    }
  }
}
</style>



