<template>
  <div style="height:100%">
    <button class="btn">{{btnTitle}}</button>
    <div class="btn btn_me">{{btnTitle}}</div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  props: {
    btnTitle: {
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.btn_me{
  display: none;
}
.btn {
  width: 240px;
  height: 60px;
  background: #FFF;
  // box-shadow: 1px 1px 32px 1px rgba(183, 28, 34, 0.24);
  border-radius: 6px;
  border: none;
  // margin-top: 89px;
  cursor: pointer;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  // color: #ffffff;
}
.btn:focus {
  outline: none;
}
.span_email {
  cursor: pointer;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  margin-left: 45px;
  img {
    vertical-align: middle;
    margin-right: 12px;
    width: 28px;
  }
  a {
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
  }
}

@media (max-width: 1000px) {
  .btn {
    display: none;
    width: 116px;
    border-radius: 3px;
    height: 20px;
    // line-height: 20px;
    font-size: 10px;
  }
  .btn_me{
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    // line-height: 100%;
  }
  .btn:focus {
    outline: none;
  }
  .span_email {
    cursor: pointer;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    margin-left: 45px;
    img {
      vertical-align: middle;
      margin-right: 12px;
      width: 28px;
    }
    a {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
  }
}
</style>
