<template>
  <div class="page2">
    <div class="header_img">
      <img
        style="width: 100%; margin: 10px 0"
        src="../assets/home/page2/bj.jpg"
        alt=""
      />
    </div>
    <div>
      <ul>
        <li v-for="(item, index) in articleList" :key="index">
          <div style="display: flex; justify-content: space-between">
            <span
              >{{ item.title.slice(0, 28)
              }}{{ item.title.length > 28 ? "..." : "" }}</span
            >
            <!-- <span @click="particulars(item.id)" style="color: #b71c21"
              >了解详情></span
            > -->
            <!-- <span  style="color: #b71c21"
              >了解详情></span
            > -->
          </div>
        </li>
      </ul>
    </div>
    
  </div>
  
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { requestMenu, requestArticleDetail, requestArticleList } from "@/api";

export default {
  name: "pag2",
  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      menuList: [],
      articleList: [],
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
    };
  },
  computed: {},
  created() {
    this.getMenu();
  },

  methods: {
    async getMenu() {
      let { data } = await requestMenu({ menuId: this.menuData.menuId });

      console.log(data);
      this.menuList = data;
      if (this.menuList.length > 0) {
        this.requestArticleList(this.menuList[0].menuId);
        // this.activeIndex = this.menuList[0].menuId;
      }
      // this.intnData();
    },

    // 文章
    requestArticleList(id, index) {
      let that = this;
      requestArticleList({
        menuId: id,
        ...this.page,
      }).then((res) => {
        that.articleList = res.data;
        that.page = res.page;
      });

      //   console.log(data);
    },

    //文章详情
    particulars(id) {
      this.$router.push({
        path: "/pageParticulars",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .page2 {
    width: 92%;
    margin: 0 auto;
  }
  ul {
    li {
      margin: 14px 0;
      div {
        span {
          display: inline-block;
          font-size: 12px;
        }
      }
    }
  }
}
</style>



